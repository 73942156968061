import React from "react";
import { Document } from "@react-pdf/renderer";
import { OrderPdfPage } from "../pages";
import { OrderProps } from "../../../interfaces/interfaces";

export const OrderFile = <T,>({ data }: { data: OrderProps }) => {
  return (
    <Document>
      <OrderPdfPage<T> orderData={data} />
    </Document>
  );
};

import React, { Ref } from "react";
import {
  ItemProps,
  OrderLineProps,
  OrderProps,
  ReportProps,
  RoleProps,
  UserDataProps,
} from "../interfaces/interfaces";
import { ColumnsType } from "antd/es/table";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue } from "antd/es/table/interface";
import { InputRef } from "antd";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface Props {
  isLoading: boolean;
  tableParams: TableParams;
  dataTableSize: number;
  page: number;
  inventoryReportData: ReportProps | undefined;
  orderData: OrderProps[];
  itemData: ItemProps[];
  userData: UserDataProps[];
  roleData: RoleProps[];
  orderToEdit: OrderProps | undefined;
  orderLineToEdit: OrderLineProps | undefined;
  itemToEdit: ItemProps | undefined;
  roleToEdit: RoleProps | undefined;
  userToEdit: UserDataProps | undefined;
  formattedItemList: { label: string; value: string }[];
  rigNumbers: { text: string; value: string }[];
  drillingCompanies: { text: string; value: string }[];
  itemList: ItemProps[];
  selectedData: any[];
  searchQuery: string;
  onChangeSearchQuery: (newValue: string) => void;
  handleSearch: (value: string) => void;
  fetchDataReport: ({}) => void;
  fetchOrderData: () => void;
  fetchItemData: () => void;
  fetchRoleData: () => void;
  fetchUserData: () => void;
  onEditOrder: React.Dispatch<React.SetStateAction<OrderProps | undefined>>;
  onEditOrderLine: React.Dispatch<
    React.SetStateAction<OrderLineProps | undefined>
  >;
  onEditItem: React.Dispatch<React.SetStateAction<ItemProps | undefined>>;
  onEditRole: React.Dispatch<React.SetStateAction<RoleProps | undefined>>;
  onEditUser: React.Dispatch<React.SetStateAction<UserDataProps | undefined>>;
  onMultipleSelect: <T>(
    data: ColumnsType<T>,
    selectedRows: React.Key[]
  ) => void;
  onExportExcel: <T>(type: "order" | "item") => void;
  handleEditOrder: (order: OrderProps) => void;
  handleCreateOrder: (order: OrderProps) => void;
  handleCreateItem: (item: ItemProps) => void;
  handleEditItem: (item: ItemProps) => void;
  handleEditRole: (role: RoleProps) => void;
  handleCreateUser: (user: UserDataProps) => void;
  handleEditUser: (user: UserDataProps) => void;
  handleRemoveSelectedData: (endpoint: string) => void;
}

const DataContext = React.createContext<Props>({
  isLoading: false,
  tableParams: {},
  dataTableSize: 10,
  page: 1,
  inventoryReportData: { dataOrders: [], dataStatus: {}, dataTotal: {} },
  orderData: [],
  itemData: [],
  userData: [],
  roleData: [],
  orderToEdit: undefined,
  orderLineToEdit: undefined,
  itemToEdit: undefined,
  roleToEdit: undefined,
  userToEdit: undefined,
  itemList: [],
  selectedData: [],
  formattedItemList: [],
  rigNumbers: [],
  drillingCompanies: [],
  searchQuery: "",
  onChangeSearchQuery: () => {},
  handleSearch: () => {},
  fetchDataReport: () => {},
  fetchOrderData: () => {},
  fetchItemData: () => {},
  fetchRoleData: () => {},
  fetchUserData: () => {},
  onEditOrder: () => {},
  onEditOrderLine: () => {},
  onEditItem: () => {},
  onEditRole: () => {},
  onEditUser: () => {},
  onMultipleSelect: () => {},
  onExportExcel: () => {},
  handleEditOrder: () => {},
  handleCreateOrder: () => {},
  handleCreateItem: () => {},
  handleEditItem: () => {},
  handleEditRole: () => {},
  handleCreateUser: () => {},
  handleEditUser: () => {},
  handleRemoveSelectedData: () => {},
});

export default DataContext;

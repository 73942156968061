import { Drawer } from "antd";
import React, { useState } from "react";
import { PDFView } from "../components/pdf/pdfView";
import { DataToPdfProps } from "../interfaces/interfaces";
import { order_initial_state } from "../utils/data";

export const usePdf = <T,>() => {
  const [isPdf, setIsPdf] = useState(false);
  const [dataToPdf, setDataToPdf] = useState<DataToPdfProps>({
    source: "order",
    data: [],
  });

  const displayPdfFile = <T,>({ source, data }: DataToPdfProps) => {
    setDataToPdf({
      source,
      data,
    });
    setIsPdf(true);
  };

  const handleClosePdfViewer = () => {
    setIsPdf(false);
    setDataToPdf({
      source: "order",
      data: order_initial_state,
    });
  };

  const getPdfDrawer = () => {
    return (
      <>
        <Drawer
          title="Export to PDF"
          width={"85%"}
          onClose={handleClosePdfViewer}
          open={isPdf}
        >
          <PDFView<T> dataToPdf={dataToPdf} action={() => {}} />
        </Drawer>
      </>
    );
  };

  return {
    displayPdfFile,
    componentPdf: getPdfDrawer(),
  };
};

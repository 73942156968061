import React, { useContext, useEffect, useState } from "react";
import { Button, Drawer, Form, Space, Tooltip } from "antd";
import { ItemForm } from "./ItemForm";
import DataContext from "../../../context/DataContext";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useAlertPrevent } from "../../../hooks";

interface Props {
  open: boolean;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
  onClose: () => void;
}

const INITIAL_STATE = {
  OID: 1,
  key: 0,
  Name: "",
  Description: "",
  Price: 0,
};

export const ItemDrawer = ({
  open,
  onClickNext,
  onClickPrevious,
  onClose,
}: Props) => {
  const [form] = Form.useForm();
  const { itemToEdit, handleCreateItem, handleEditItem } =
    useContext(DataContext);
  const { modalAlertPrevent, openAlertPrevent } = useAlertPrevent();
  const [values, setValues] = useState(INITIAL_STATE);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    itemToEdit && setValues(itemToEdit);
    return () => {
      setValues(INITIAL_STATE);
    };
  }, [itemToEdit]);

  const handleSaveItem = () => {
    form
      .validateFields()
      .then(() => {
        itemToEdit ? handleEditItem(values) : handleCreateItem(values);
      })
      .then(() => {
        setValues(INITIAL_STATE);
        setHasChanged(false);
        onClose();
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo);
      });
  };

  const handleAutoSave = () => {
    if (hasChanged) {
      openAlertPrevent();
      setHasChanged(false);
    } else {
      onClose();
    }
  };
  return (
    <>
      <Drawer
        title={itemToEdit ? "Edit Item" : "Create new Item"}
        width={720}
        onClose={handleAutoSave}
        maskClosable={false}
        open={open}
        extra={
          <Space>
            {itemToEdit && (
              <>
                <Tooltip title="Previous Record">
                  <Button
                    disabled={!onClickPrevious}
                    icon={<LeftOutlined />}
                    onClick={onClickPrevious}
                  ></Button>
                </Tooltip>
                <Tooltip title="Next Record">
                  <Button
                    disabled={!onClickNext}
                    icon={<RightOutlined />}
                    onClick={onClickNext}
                  ></Button>
                </Tooltip>
              </>
            )}
          </Space>
        }
        footer={
          <Space className="padding-all">
            {itemToEdit && (
              <Button onClick={onClose} danger>
                Remove
              </Button>
            )}
            <Button htmlType="submit" type="primary" onClick={handleSaveItem}>
              Save Item
            </Button>
          </Space>
        }
      >
        <ItemForm
          values={values}
          setValues={setValues}
          form={form}
          onClickField={() => {
            setHasChanged(true);
          }}
        />
      </Drawer>
      {modalAlertPrevent({
        type: "autosave",
        next: () => {
          handleSaveItem();
        },
        cancel: () => {
          onClose();
        },
      })}
    </>
  );
};

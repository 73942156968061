import React, { useContext } from "react";
import { Button, Col, Dropdown, Grid, Row } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { routes } from "../../utils/navigation/MenuItems";

type Props = {
  collapsed: boolean;
  handleClick: () => void;
};

const supportPage = `https://support.quattroapps.app/?token=${localStorage.getItem(
  "info"
)}`;

export const AppHeader = ({ collapsed, handleClick }: Props) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const { onLogout } = useContext(AuthContext);

  const items: MenuProps["items"] = [
    {
      label: (
        <Link to={supportPage} target="_blank">
          Help
        </Link>
      ),
      key: routes.help,
      icon: <QuestionCircleOutlined className="icon" />,
    },
    {
      label: <Link to="/">Logout</Link>,
      key: "4",
      icon: <LogoutOutlined className="icon" />,
      onClick: onLogout,
    },
  ];
  return (
    <>
      <Row
        align="middle"
        style={{
          marginLeft: "0",
          marginRight: "0",
          paddingTop: "0",
          height: "100%",
        }}
      >
        <Col xs={10} md={3}>
          {!xs ? (
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={handleClick}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          ) : (
            <></>
          )}
        </Col>

        <Col xs={10} md={20}></Col>
        <Col xs={4} md={1}>
          <Dropdown menu={{ items }} trigger={["hover"]}>
            <UserOutlined className="icon" style={{ cursor: "pointer" }} />
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Divider, Drawer, Form, Space, Tooltip } from "antd";
import { OrderLineProps, OrderProps } from "../../../interfaces/interfaces";
import { Datatable } from "../../functional";
import { OrderLinesTableColumns } from "../../../utils/tables/columns";
import { OrderLineForm } from "./OrderLineForm";
import { OrderForm } from "./OrderForm";
import {
  order_initial_state,
  order_line_initial_state,
} from "../../../utils/data";
import DataContext from "../../../context/DataContext";
import { useAlertPrevent, usePdf } from "../../../hooks";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

interface Props {
  open: boolean;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
  onClose: () => void;
}

export const OrderDrawer = ({
  open,
  onClickPrevious,
  onClickNext,
  onClose,
}: Props) => {
  const [form] = Form.useForm();
  const {
    orderToEdit,
    handleCreateOrder,
    orderLineToEdit,
    onEditOrderLine,
    handleEditOrder,
  } = useContext(DataContext);
  const { modalAlertPrevent, openAlertPrevent } = useAlertPrevent();
  const { displayPdfFile, componentPdf } = usePdf<OrderProps>();
  const [values, setValues] = useState(order_initial_state);
  const [valuesOrderLine, setValuesOrderLine] = useState(
    order_line_initial_state
  );
  const [openOrderLines, setOpenOrderLines] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    orderToEdit && setValues(orderToEdit);
    return () => {
      setValues(order_initial_state);
    };
  }, [orderToEdit]);

  useEffect(() => {
    orderLineToEdit && setValuesOrderLine(orderLineToEdit);
    return () => {
      setValuesOrderLine(order_line_initial_state);
    };
  }, [orderLineToEdit]);

  const editOrderLine = (orderLineItem: OrderLineProps) => {
    setValues((prev) => {
      return {
        ...prev,
        OrderLines: prev.OrderLines.map((orderLine) => {
          if (orderLine.OID === orderLineItem.OID)
            return { ...orderLineItem, isCreated: false };
          return orderLine;
        }),
      };
    });
  };

  const addOrderLine = (orderLineItem: OrderLineProps) => {
    setValues((prev) => {
      return {
        ...prev,
        OrderLines: [...prev.OrderLines, { ...orderLineItem, isCreated: true }],
      };
    });
  };

  const handleSaveOrder = () => {
    form
      .validateFields()
      .then(() => {
        orderToEdit ? handleEditOrder(values) : handleCreateOrder(values);
      })
      .then(() => {
        setValues(order_initial_state);
        setHasChanged(false);
        onClose();
      })
      .catch((errorInfo) => {
        console.log("errorInfo ...", errorInfo);
      });
  };

  const handleAutoSave = () => {
    if (hasChanged) {
      openAlertPrevent();
      setHasChanged(false);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Drawer
        title={orderToEdit ? "Edit Order" : "Create new Order"}
        width={1000}
        onClose={handleAutoSave}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleAutoSave();
          }
        }}
        maskClosable={false}
        open={open}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() =>
                displayPdfFile<OrderProps>({ source: "order", data: values })
              }
            >
              Print
            </Button>
            {orderToEdit && (
              <>
                <Tooltip title="Previous Record">
                  <Button
                    disabled={!onClickPrevious}
                    icon={<LeftOutlined />}
                    onClick={onClickPrevious}
                  ></Button>
                </Tooltip>
                <Tooltip title="Next Record">
                  <Button
                    disabled={!onClickNext}
                    icon={<RightOutlined />}
                    onClick={onClickNext}
                  ></Button>
                </Tooltip>
              </>
            )}
          </Space>
        }
        footer={
          <Space className="padding-all">
            {orderToEdit && (
              <Button onClick={onClose} danger>
                Remove
              </Button>
            )}
            <Button htmlType="submit" type="primary" onClick={handleSaveOrder}>
              Save Order
            </Button>
          </Space>
        }
      >
        <OrderForm
          values={values}
          setValues={setValues}
          form={form}
          onClickField={() => {
            setHasChanged(true);
          }}
        />

        <Divider>
          <Button onClick={() => setOpenOrderLines(true)} type="primary">
            New Order Line
          </Button>
        </Divider>
        <Card bordered hoverable className="card overflow_x">
          <Datatable<OrderLineProps>
            columns={OrderLinesTableColumns}
            data={values.OrderLines}
            rowKey="OID"
            onRowClick={(orderLine) => {
              onEditOrderLine(orderLine);
              setOpenOrderLines(true);
            }}
            isPaginated={false}
          />
        </Card>
      </Drawer>
      <OrderLineForm
        open={openOrderLines}
        values={valuesOrderLine}
        orderId={values.OID}
        orderLinesLength={values.OrderLines.length || 0}
        isEdition={!!orderLineToEdit}
        setValues={setValuesOrderLine}
        onClose={() => {
          onEditOrderLine(undefined);
          setOpenOrderLines(false);
        }}
        onEditOrderLine={editOrderLine}
        onAddOrderLine={addOrderLine}
      />
      <>{componentPdf}</>
      {modalAlertPrevent({
        type: "autosave",
        next: () => {
          handleSaveOrder();
        },
        cancel: () => {
          onClose();
        },
      })}
    </>
  );
};

import { Card, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Datatable } from "../../components/functional";
import { RoleProps } from "../../interfaces/interfaces";
import { RoleTableColumns } from "../../utils/tables/columns";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { DeleteOutlined } from "@ant-design/icons";
import DataContext from "../../context/DataContext";
import { RoleDrawer } from "../../components/pages/role";

export const RolePage = () => {
  const { roleData, fetchRoleData, onEditRole } = useContext(DataContext);
  const [roleIndex, setRoleIndex] = useState<number | undefined>(undefined);
  const [openRoleDrawer, setOpenRoleDrawer] = useState(false);

  useEffect(() => {
    fetchRoleData();
  }, []);

  const handleClickNext = (roleIndex: number) => {
    setRoleIndex(roleIndex + 1);
    onEditRole(roleData[roleIndex + 1]);
  };

  const handleClickPrevious = (roleIndex: number) => {
    setRoleIndex(roleIndex - 1);
    onEditRole(roleData[roleIndex - 1]);
  };
  const buttons = [
    {
      title: "Remove",
      icon: <DeleteOutlined />,
      disabled: false,
      danger: true,
      action: () => {},
    },
  ];
  return (
    <>
      <Row>
        <ColumnComponent>
          <CardTitle title="Roles" buttons={buttons} />
        </ColumnComponent>
        <ColumnComponent>
          <Card bordered hoverable className="card overflow_x">
            <Datatable<RoleProps>
              columns={RoleTableColumns}
              data={roleData}
              rowKey="Oid"
              onRowClick={(role) => {
                setRoleIndex(roleData.indexOf(role));
                onEditRole(role);
                setOpenRoleDrawer(true);
              }}
            />
          </Card>
        </ColumnComponent>
      </Row>
      <RoleDrawer
        open={openRoleDrawer}
        onClose={() => {
          onEditRole(undefined);
          setOpenRoleDrawer(false);
        }}
        onClickNext={
          roleIndex !== undefined && roleIndex < roleData.length - 1
            ? () => handleClickNext(roleIndex)
            : undefined
        }
        onClickPrevious={
          roleIndex !== undefined && roleIndex > 0
            ? () => handleClickPrevious(roleIndex)
            : undefined
        }
      />
    </>
  );
};

import { Dayjs } from "dayjs";

export const useDate = () => {
  const getStringFromDate = (date: Dayjs): string => {
    return new Date(date.toString()).toISOString().split("T")[0]; // yyyy-mm-dd
  };
  const getDateFromString = (date: string) => {
    return new Date(date).toLocaleDateString().split("T")[0];
  };

  const getDateFromDatabaseString = (date: string) => {
    return date.split("T")[0];
  };

  const getDateTimeFromDatabaseString = (date: string) => {
    return date.split(".")[0];
  };
  return {
    getStringFromDate,
    getDateFromString,
    getDateFromDatabaseString,
    getDateTimeFromDatabaseString,
  };
};

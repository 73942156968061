import React from "react";
import { Page, Text, Image, StyleSheet } from "@react-pdf/renderer";
import desktopLogo from "../../../assets/images/company_logo.png";
import Footer from "../components/Footer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  page: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "50px",
    fontSize: 14,
    paddingBottom: "200px",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  mainHeader: {
    fontSize: 22,
    fontWeight: "heavy",
    display: "flex",
    alignItems: "center",
    fontFamily: "Times-Roman",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
});

export const DefaultPdfPage = () => {
  const today = new Date().toLocaleDateString("en-US");
  return (
    <Page style={styles.page}>
      <Image style={styles.image} src={desktopLogo} />
      <Text style={styles.mainHeader} fixed>
        Crystin Manufacturing
      </Text>
      <Text style={styles.header} fixed>
        {today}
      </Text>
      <Footer />
    </Page>
  );
};

import React from "react";
import { Alert } from "antd";

interface Props {
  message: string;
  description?: string;
  type: "error" | "warning" | "info" | "success";
}

export const AlertComponent = ({ message, description, type }: Props) => {
  return (
    <Alert
      className="alert"
      message={message}
      description={description}
      type={type}
      showIcon
      closable
    />
  );
};

import React from "react";
import { Page, StyleSheet, Text, Image } from "@react-pdf/renderer";
import Footer from "../components/Footer";
import Table from "../components/Table";
import { Header } from "../components/Header";
import { OrderProps } from "../../../interfaces/interfaces";
import { useDate } from "../../../hooks";
import checkBoxFill from "../../../assets/utils/check_box_fill.png";
import checkBoxBlank from "../../../assets/utils/check_box_blank.png";

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingTop: 20,
    paddingBottom: 80,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: "right",
    fontFamily: "Times-Roman",
    fontWeight: 800,
    borderBottomColor: "rgb(251,171,53)",
  },
  displayNone: {
    color: "#FFF",
  },
  pushTop: {
    marginTop: 20,
  },
  item: {},
  checkbox: {
    paddingTop: "100px",
    height: 10,
  },
});

export const OrderPdfPage = <T,>({ orderData }: { orderData: OrderProps }) => {
  const { getStringFromDate } = useDate();

  return (
    <Page size="A4" orientation="portrait" style={styles.page}>
      <Header />
      <Text style={styles.title}>Order Ticket</Text>
      <React.Fragment>
        <Table>
          <Table.Body>
            {orderData && (
              <>
                <Table.Row>
                  <Table.Cell>OIL COMPANY:</Table.Cell>
                  <Table.Cell>{orderData.OilCompany}</Table.Cell>
                  <Table.Cell>STATUS:</Table.Cell>
                  <Table.Cell>{orderData.Status}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>RIG NUMBER:</Table.Cell>
                  <Table.Cell>{orderData.RigNumber}</Table.Cell>
                  <Table.Cell>DATE RECEIVED:</Table.Cell>
                  <Table.Cell>
                    {orderData.DateReceived
                      ? getStringFromDate(orderData.DateReceived)
                      : orderData.DateReceived}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>DRILLING COMPANY:</Table.Cell>
                  <Table.Cell>{orderData.DrillingCompany}</Table.Cell>
                  <Table.Cell>DATE DOPED:</Table.Cell>
                  <Table.Cell>
                    {orderData.DateDoped
                      ? getStringFromDate(orderData.DateDoped)
                      : ""}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>INBOUND TRUCKING CO:</Table.Cell>
                  <Table.Cell>{orderData.InboundTruckingCompany}</Table.Cell>
                  <Table.Cell>LOCATION:</Table.Cell>
                  <Table.Cell>{orderData.Location}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>INBOUND TRUCKING DRIVER:</Table.Cell>
                  <Table.Cell>{orderData.InboundTruckingDriver}</Table.Cell>
                  <Table.Cell>FINAL INSPECTION DATE:</Table.Cell>
                  <Table.Cell>
                    {orderData.FinalInspectionDate
                      ? getStringFromDate(orderData.FinalInspectionDate)
                      : ""}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>OUTBOUND TRUCKING CO:</Table.Cell>
                  <Table.Cell>{orderData.OutboundTruckingCompany}</Table.Cell>
                  <Table.Cell>FINAL INSPECTION PREFORMED BY:</Table.Cell>
                  <Table.Cell>
                    {orderData.FinalInspectionPreformedBy}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>OUTBOUND TRUCKING NAME:</Table.Cell>
                  <Table.Cell>{orderData.OutboundTruckingName}</Table.Cell>
                  <Table.Cell>RELEASE FOR SHIPMENT:</Table.Cell>
                  <Table.Cell>{orderData.ReleaseForShipment}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>DATE SHIPPED:</Table.Cell>
                  <Table.Cell>
                    {orderData.DateShipped
                      ? getStringFromDate(orderData.DateShipped)
                      : ""}
                  </Table.Cell>
                </Table.Row>
                <>
                  {orderData.OrderLines.length > 0 ? (
                    <>
                      {orderData.OrderLines.map((orderLine) => (
                        <React.Fragment key={orderLine.OID}>
                          <Table.Row additionalStyles={styles.pushTop}>
                            <Table.Cell>ITEM:</Table.Cell>
                            <Table.Cell>{orderLine.itemDetail.Name}</Table.Cell>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell>QTY</Table.Cell>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell>QTY</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>QTY REMAINING:</Table.Cell>
                            <Table.Cell>{orderLine.QtyRemaining}</Table.Cell>
                            <Table.Cell additionalStyles={styles.item}>
                              <Image
                                src={
                                  orderLine.RCPins
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              RC Pins
                            </Table.Cell>
                            <Table.Cell>{orderLine.RCPinsQty || 0}</Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.Straignten
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              Straighten
                            </Table.Cell>
                            <Table.Cell>
                              {orderLine.StraigntenQty || 0}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.RCBoxes
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              RC Boxes
                            </Table.Cell>
                            <Table.Cell>{orderLine.RCBoxesQty || 0}</Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.PinHardband
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              Pin Hardband
                            </Table.Cell>
                            <Table.Cell>
                              {orderLine.PinHardbandQty || 0}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.RFPins
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              RF Pins
                            </Table.Cell>
                            <Table.Cell>{orderLine.RFPinsQty || 0}</Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.Phosphate
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              Phosphate
                            </Table.Cell>
                            <Table.Cell>
                              {orderLine.PhosphateQty || 0}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell additionalStyles={styles.displayNone}>
                              0
                            </Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.RFBoxes
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              RF Boxes
                            </Table.Cell>
                            <Table.Cell>{orderLine.RFBoxesQty || 0}</Table.Cell>
                            <Table.Cell>
                              <Image
                                src={
                                  orderLine.BoxHardband
                                    ? checkBoxFill
                                    : checkBoxBlank
                                }
                                style={styles.checkbox}
                              />{" "}
                              Box Hardband
                            </Table.Cell>
                            <Table.Cell>
                              {orderLine.BoxHardbandQty || 0}
                            </Table.Cell>
                          </Table.Row>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </>
              </>
            )}
          </Table.Body>
        </Table>
      </React.Fragment>
      <Footer />
    </Page>
  );
};

import { Card, Grid, Tooltip } from "antd";
import React, { ReactNode } from "react";
import { SearchInput } from "./SearchInput";

interface ButtonProps {
  title?: string;
  icon?: JSX.Element;
  disabled?: Boolean;
  danger?: Boolean;
  action: () => void;
}

interface Props {
  title: string;
  buttons?: ButtonProps[];
  search?: boolean;
}

export const CardTitle = ({ title, buttons, search = false }: Props) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const blankSpaceLength = buttons && 100 - buttons.length * (xs ? 20 : 10);
  const blankGrid: React.CSSProperties = {
    width: `${blankSpaceLength}%`,
  };

  return (
    <Card
      bordered={false}
      size="small"
      title={title}
      extra={search && <SearchInput />}
    >
      {buttons && <Card.Grid style={blankGrid} hoverable={false}></Card.Grid>}
      {buttons && (
        <>
          {buttons.map((button) => (
            <Tooltip title={button.title} key={button.title}>
              <Card.Grid
                style={{
                  width: xs ? "20%" : "10%",
                  textAlign: "center",
                  padding: "10px 5px",
                  cursor: button.disabled ? "not-allowed" : "pointer",
                  pointerEvents: button.disabled && "none",
                }}
                className={button.disabled ? "card_grid_disabled" : "card_grid"}
                hoverable
                onClick={button.action}
              >
                <span style={{ marginRight: "10px" }}>{button.icon}</span>
                {!xs && <span>{button.title}</span>}
              </Card.Grid>
            </Tooltip>
          ))}
        </>
      )}
    </Card>
  );
};

import React from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/ui";
import { MenuItems } from "../../utils/navigation/MenuItems";

const Navbar = ({ menuCollapsed }: { menuCollapsed: boolean }) => {
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = ({ key }) => {
    navigate(key);
  };

  return (
    <>
      <Logo mobile={menuCollapsed} />
      <Menu
        className="pt-2"
        onClick={onClick}
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={MenuItems}
      />
    </>
  );
};

export default Navbar;

import React, { useEffect } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Spinner } from "../ui/Spinner";
import { Button, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { DataToPdfProps } from "../../interfaces/interfaces";
import { OrderFile, ItemFile, DefaultFile } from "./files/";

interface Props {
  dataToPdf: DataToPdfProps;
  action: () => void;
}

export const PDFView = <T,>({ dataToPdf, action }: Props) => {
  useEffect(() => {
    if (action) action();
  }, []);

  const getChildren = () => {
    switch (dataToPdf.source) {
      case "order":
        return <OrderFile<T> data={dataToPdf.data} />;
      case "item":
        return <ItemFile<T> data={dataToPdf.data} />;
      case "default":
        return <DefaultFile />;
      default:
        return <DefaultFile />;
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          right: "10vw",
          top: "30vh",
        }}
      >
        <PDFDownloadLink document={getChildren()} fileName={dataToPdf.source}>
          {({ loading }) =>
            loading ? (
              <Spinner />
            ) : (
              <Tooltip title="Download">
                <Button
                  type="default"
                  icon={<DownloadOutlined />}
                  size="large"
                ></Button>
              </Tooltip>
            )
          }
        </PDFDownloadLink>
      </div>
      <PDFViewer height={"100%"} width={"100%"}>
        {getChildren()}
      </PDFViewer>
    </>
  );
};

import React from "react";
import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ProtectedRoute } from "./layout/routes/ProtectedRoutes";
import { PublicRoute } from "./layout/routes/PublicRoutes";
import { config } from "./utils/theme/config";
import { AppPages } from "./utils/navigation/MenuItems";
import AuthProvider from "./context/AuthProvider";
import DataProvider from "./context/DataProvider";
import "./globals.css";

const App: React.FC = () => (
  <AuthProvider>
    <DataProvider>
      <ConfigProvider theme={config}>
        <Routes>
          {AppPages.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <ProtectedRoute>{route.component}</ProtectedRoute>
                ) : (
                  <PublicRoute>{route.component}</PublicRoute>
                )
              }
            />
          ))}
        </Routes>
      </ConfigProvider>
    </DataProvider>
  </AuthProvider>
);

export default App;

import React from "react";
import { Document } from "@react-pdf/renderer";
import { DefaultPdfPage } from "../pages";

export const DefaultFile = () => {
  return (
    <Document>
      <DefaultPdfPage />
    </Document>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Drawer, Form, Space, Tooltip } from "antd";
import { RoleProps } from "../../../interfaces/interfaces";
import { RoleForm } from "./RoleForm";
import { role_initial_state } from "../../../utils/data";
import DataContext from "../../../context/DataContext";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

interface Props {
  open: boolean;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
  onClose: () => void;
}

export const RoleDrawer = ({
  open,
  onClickNext,
  onClickPrevious,
  onClose,
}: Props) => {
  const [form] = Form.useForm();
  const { roleToEdit, handleEditRole } = useContext(DataContext);
  const [values, setValues] = useState(role_initial_state);

  useEffect(() => {
    roleToEdit && setValues(roleToEdit);
    return () => {
      setValues(role_initial_state);
    };
  }, [roleToEdit]);

  return (
    <>
      <Drawer
        title={roleToEdit ? "Edit Role" : "Create new Role"}
        width={720}
        onClose={onClose}
        maskClosable={false}
        open={open}
        extra={
          <Space>
            {roleToEdit && (
              <>
                <Tooltip title="Previous Record">
                  <Button
                    disabled={!onClickPrevious}
                    icon={<LeftOutlined />}
                    onClick={onClickPrevious}
                  ></Button>
                </Tooltip>
                <Tooltip title="Next Record">
                  <Button
                    disabled={!onClickNext}
                    icon={<RightOutlined />}
                    onClick={onClickNext}
                  ></Button>
                </Tooltip>
              </>
            )}
          </Space>
        }
        footer={
          <Space className="padding-all">
            {roleToEdit && (
              <Button onClick={onClose} danger>
                Remove
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    roleToEdit
                      ? handleEditRole(values)
                      : handleEditRole(values);
                  })
                  .then(() => {
                    onClose();
                  })
                  .catch((errorInfo) => {
                    console.log("errorInfo ...", errorInfo);
                  });
              }}
            >
              Save Role
            </Button>
          </Space>
        }
      >
        <RoleForm values={values} setValues={setValues} form={form} />
      </Drawer>
    </>
  );
};

import React, { useContext } from "react";
import { LoginForm } from "../../components/login";
import AuthContext from "../../context/AuthContext";
import { AlertComponent } from "../../components/ui";

export const Login = () => {
  const { onLogin, isSuccess, isError } = useContext(AuthContext);
  return (
    <>
      <LoginForm onClickLogin={onLogin} />
      {isSuccess && (
        <AlertComponent type="success" message="Succesfully logged in" />
      )}
      {isError && (
        <AlertComponent
          type="error"
          message="Not Authorized, please contact the Administrator"
        />
      )}
    </>
  );
};

import React from "react";
import { ColumnsType } from "antd/es/table";
import {
  ItemProps,
  OrderLineProps,
  OrderProps,
  OrderReport,
  RoleProps,
  UserDataProps,
  reportProps,
} from "../../interfaces/interfaces";
import { Button, Checkbox } from "antd";
import { EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";

export const OrderTableColumns: ColumnsType<OrderProps> = [
  {
    title: "OID",
    dataIndex: "OID",
    key: "OID",
  },
  {
    title: "Edit",
    dataIndex: "X",
    render: () => <Button icon={<EditFilled />}></Button>,
  },
  {
    title: "Drilling Company",
    dataIndex: "DrillingCompany",
  },
  {
    title: "Rig Number",
    dataIndex: "RigNumber",
  },
  {
    title: "Location",
    dataIndex: "Location",
  },
  {
    title: "Status",
    dataIndex: "Status",
  },
  {
    title: "EstimateNo",
    dataIndex: "EstimateNo",
  },
  {
    title: "PO #",
    dataIndex: "PurchaseOrderNo",
  },
  {
    title: "Oil Company",
    dataIndex: "OilCompany",
  },
  {
    title: "Date Received",
    dataIndex: "DateReceived",
    render: (params) => <>{params ? dayjs(params).format("MM/DD/YYYY") : ""}</>,
  },
  {
    title: "Date Doped",
    dataIndex: "DateDoped",
    render: (params) => <>{params ? dayjs(params).format("MM/DD/YYYY") : ""}</>,
  },
  {
    title: "Date Shipped",
    dataIndex: "DateShipped",
    render: (params) => <>{params ? dayjs(params).format("MM/DD/YYYY") : ""}</>,
  },
];

export const OrderLinesTableColumns: ColumnsType<OrderLineProps> = [
  {
    title: "Edit",
    dataIndex: "X",
    render: () => <Button icon={<EditFilled />}></Button>,
  },
  {
    title: "Item Name",
    dataIndex: "itemDetail",
    width: "10vw",
    render: (params) => <>{params ? params.Name : ""}</>,
  },
  {
    title: "Item Qty",
    dataIndex: "ItemQty",
  },
  {
    title: "Qty Remaining",
    dataIndex: "QtyRemaining",
  },
  {
    title: "RCPins",
    dataIndex: "RCPins",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "RCBoxes",
    dataIndex: "RCBoxes",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "RFPins",
    dataIndex: "RFPins",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "RFBoxes",
    dataIndex: "RFBoxes",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Straignten",
    dataIndex: "Straignten",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Pin Hardband",
    dataIndex: "PinHardband",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Box Hardband",
    dataIndex: "BoxHardband",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Phosphate",
    dataIndex: "Phosphate",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Pin Protector",
    dataIndex: "Phosphate",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Box Protector",
    dataIndex: "BoxProtector",
    render: (params) => <Checkbox checked={params} />,
  },
];

export const ItemTableColumns: ColumnsType<ItemProps> = [
  {
    title: "Edit",
    dataIndex: "X",
    render: () => <Button icon={<EditFilled />}></Button>,
  },
  {
    title: "Name",
    dataIndex: "Name",
  },
  {
    title: "Description",
    dataIndex: "Description",
  },
  {
    title: "Price",
    dataIndex: "Price",
    render: (params) => <>{"$ " + params}</>,
  },
];

export const OrderInventoryTableColumns: ColumnsType<OrderReport> = [
  {
    title: "Status",
    dataIndex: "Status",
    fixed: "left",
    width: 120,
    filters: [
      {
        text: "Shipped",
        value: "Shipped",
      },
      {
        text: "Received",
        value: "Received",
      },
      {
        text: "Doped",
        value: "Doped",
      },
    ],
    filterSearch: true,
    filterMode: "tree",
    sorter: (a, b) => a.Status.localeCompare(b.Status),
  },
  {
    title: "Received",
    dataIndex: "DateReceived",
    render: (params) => (
      <>{params ? new Date(params).toISOString().split("T")[0] : ""}</>
    ),
    fixed: "left",
    width: 100,
  },
  {
    title: "Drilling Co",
    dataIndex: "DrillingCompany",
    fixed: "left",
    width: 120,
    filters: [
      {
        text: "Nabors",
        value: "Nabors",
      },
      {
        text: "H&P",
        value: "H&P",
      },
    ],
    filterSearch: true,
    filterMode: "tree",
    onFilter: (value: any, record: OrderReport) =>
      record.DrillingCompany.includes(value),
    sorter: (a, b) => a.DrillingCompany.localeCompare(b.DrillingCompany),
  },
  {
    title: "Rig No",
    dataIndex: "RigNumber",
    fixed: "left",
    width: 100,
    filters: [
      {
        text: "M58",
        value: "M58",
      },
      {
        text: "889",
        value: "889",
      },
      {
        text: "M30",
        value: "M30",
      },
    ],
    filterSearch: true,
    filterMode: "tree",
    onFilter: (value: any, record: OrderReport) =>
      record.RigNumber.includes(value),
    sorter: (a, b) => a.RigNumber.localeCompare(b.RigNumber),
  },
  {
    title: "Order ID",
    dataIndex: "OrderId",
    fixed: "left",
    width: 100,
    sorter: (a, b) => a.DrillingCompany.localeCompare(b.DrillingCompany),
  },
  {
    title: "Location",
    dataIndex: "Location",
    fixed: "left",
    width: 100,
    sorter: (a, b) => a.Status.localeCompare(b.Status),
  },
  // {
  //   title: "Grand Total",
  //   dataIndex: "total",
  //   fixed: "right",
  //   width: 100,
  // },
];

export const RoleTableColumns: ColumnsType<RoleProps> = [
  {
    title: "Name",
    dataIndex: "Name",
  },
  {
    title: "Is Administrative",
    dataIndex: "IsAdministrative",
    align: "center",
    render: (params) => <Checkbox checked={params} />,
  },
  {
    title: "Can Edit Model",
    dataIndex: "CanEditModel",

    render: (params) => <Checkbox checked={params} />,
  },
];

export const UserTableColumns: ColumnsType<UserDataProps> = [
  {
    title: "Username",
    dataIndex: "UserName",
  },
  {
    title: "Is Active",
    dataIndex: "IsActive",
    align: "center",
    render: (params) => <Checkbox checked={params} />,
  },
];

export const ReportTableColumns: ColumnsType<reportProps> = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Data Type",
    dataIndex: "dataType",
  },
];

import { Card, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Datatable } from "../../components/functional";
import { ItemProps } from "../../interfaces/interfaces";
import { ItemTableColumns } from "../../utils/tables/columns";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { useModal } from "../../hooks";
import DataContext from "../../context/DataContext";
import {
  FileAddOutlined,
  ExportOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ItemDrawer } from "../../components/pages/item";

export const ItemPage = () => {
  const [openItemDrawer, setOpenItemDrawer] = useState(false);
  const {
    dataTableSize,
    selectedData,
    itemData,
    handleRemoveSelectedData,
    fetchItemData,
    onExportExcel,
    onEditItem,
  } = useContext(DataContext);
  const [itemIndex, setItemIndex] = useState<number | undefined>(0);
  const { isModalOpen, setIsModalOpen, ConfirmationModal } = useModal();

  useEffect(() => {
    fetchItemData();
  }, []);

  const handleClickNext = (itemIndex: number) => {
    setItemIndex(itemIndex + 1);
    onEditItem(itemData[itemIndex + 1]);
  };

  const handleClickPrevious = (itemIndex: number) => {
    setItemIndex(itemIndex - 1);
    onEditItem(itemData[itemIndex - 1]);
  };
  const buttons = [
    {
      title: "New",
      disabled: false,
      icon: <FileAddOutlined />,
      action: () => {
        onEditItem(undefined);
        setOpenItemDrawer(true);
      },
    },
    {
      title: "Export",
      icon: <ExportOutlined />,
      disabled: !(selectedData.length > 0),
      action: () => onExportExcel("item"),
    },
    {
      title: "Remove",
      icon: <DeleteOutlined />,
      disabled: !(selectedData.length > 0),
      danger: true,
      action: () => setIsModalOpen(true),
    },
  ];
  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title="Items" buttons={buttons} search={true} />
        </ColumnComponent>
        <ColumnComponent>
          <Card bordered hoverable className="card overflow_x">
            <Datatable<ItemProps>
              columns={ItemTableColumns}
              rowKey="OID"
              data={itemData}
              onRowClick={(item, index) => {
                onEditItem(item);
                setOpenItemDrawer(true);
                setItemIndex(index);
              }}
            />
          </Card>
        </ColumnComponent>
      </Row>
      <ItemDrawer
        open={openItemDrawer}
        onClose={() => {
          onEditItem(undefined);
          setOpenItemDrawer(false);
        }}
        onClickPrevious={
          itemIndex !== undefined && itemIndex > 0
            ? () => handleClickPrevious(itemIndex)
            : undefined
        }
        onClickNext={
          itemIndex !== undefined && itemIndex < dataTableSize - 1
            ? () => handleClickNext(itemIndex)
            : undefined
        }
      />
      {isModalOpen && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onConfirm={() => handleRemoveSelectedData("items")}
        />
      )}
    </>
  );
};

import React, { ReactNode } from "react";
import { ErrorPage, SpinPage } from "../../pages";

interface Props {
  error?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}
export const DisplayComponent = ({ error, isLoading, children }: Props) => {
  if (error) return <ErrorPage />;
  if (isLoading) return <SpinPage />;
  return <>{children}</>;
};

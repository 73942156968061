export const config = {
  // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
  token: {
    colorPrimary: "#3c6444",
    colorDanger: "red",
    fontSize: 16,
    padding: 18,
  },
  components: {
    Radio: {
      colorPrimary: "#00b96b",
    },
  },
};

import React, { ReactNode, useContext, useState } from "react";
import { Grid, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import DataContext from "../../context/DataContext";

interface DataType {
  key: React.Key;
}

interface DataProps<T> {
  columns: ColumnsType<T>;
  data: T[] | [];
  rowKey?: string;
  onRowClick?: (item: T, index?: number | undefined) => void;
  scrollX?: any;
  scrollY?: any;
  summary?: () => ReactNode;
  isPaginated?: boolean;
}

export function Datatable<T extends DataType>({
  columns,
  data,
  scrollX,
  scrollY,
  rowKey,
  onRowClick,
  summary,
  isPaginated = true,
}: DataProps<T>) {
  const { useBreakpoint } = Grid;
  const { isLoading, tableParams, onMultipleSelect } = useContext(DataContext);
  const { xs } = useBreakpoint();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const size = xs ? "small" : "middle";

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    onMultipleSelect<T>(data, newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      size={size}
      bordered
      pagination={isPaginated && tableParams.pagination}
      loading={isLoading}
      rowKey={rowKey}
      rowSelection={rowSelection}
      scroll={{ x: scrollX, y: scrollY }}
      onRow={(record, index) => {
        return {
          onClick: () => {
            onRowClick && onRowClick(record, index);
          },
        };
      }}
      summary={summary}
    />
  );
}

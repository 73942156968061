import { Button, Col, Form, Input, Row, Switch, Typography } from "antd";
import React, { useState } from "react";

interface Props {
  onClickLogin: (username: string, password: string) => void;
}

export const LoginForm = ({ onClickLogin }: Props) => {
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const { Title } = Typography;
  return (
    <Row gutter={[24, 0]} justify="space-around">
      <Col>
        <Title className="align-center">Sign In</Title>
        <Title level={5} className="align-center">
          Enter your email and password to sign in
        </Title>
        <Form
          onFinish={() => onClickLogin(username, pass)}
          layout="vertical"
          className="row-col"
        >
          <Form.Item
            label="User"
            name="user"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              placeholder="User"
              name="user"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              value={pass}
              onChange={(event) => setPass(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              SIGN IN
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

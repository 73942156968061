import { Button, Input, InputRef } from "antd";
import { SearchProps } from "antd/es/input";
import React, { useContext } from "react";
import DataContext from "../../context/DataContext";

const { Search } = Input;

export const SearchInput = () => {
  const { handleSearch, searchQuery, onChangeSearchQuery } =
    useContext(DataContext);
  const onSearch: SearchProps["onSearch"] = (value) => handleSearch(value);

  return (
    <Search
      placeholder="Search"
      allowClear
      value={searchQuery}
      onChange={(e) => onChangeSearchQuery(e.target.value)}
      size="middle"
      onSearch={onSearch}
      enterButton={
        <Button
          type="primary"
          disabled={!searchQuery}
          style={{ cursor: !searchQuery ? "not-allowed" : "pointer" }}
        >
          Search
        </Button>
      }
    />
  );
};

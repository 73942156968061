import dayjs, { Dayjs } from "dayjs";
import {
  ItemProps,
  OrderInventoryProps,
  OrderLineProps,
  OrderProps,
  RoleProps,
  UserDataProps,
  reportProps,
} from "../interfaces/interfaces";

export const order_initial_state: OrderProps = {
  key: 0,
  OID: 0,
  OilCompany: "",
  RigNumber: "",
  DrillingCompany: "",
  InboundTruckingCompany: "",
  InboundTruckingDriver: "",
  OutboundTruckingCompany: "",
  OutboundTruckingName: "",
  PurchaseOrderNo: "",
  EstimateNo: 0,
  Status: "",
  DateReceived: null,
  DateDoped: null,
  Location: "",
  FinalInspectionDate: null,
  FinalInspectionPreformedBy: "",
  ReleaseForShipment: "",
  DateShipped: null,
  Notes: "",
  OrderLines: [],
};

export const item_initial_state: ItemProps = {
  OID: 1,
  key: 0,
  Name: "RXOS 525",
  Description: 'Repair 5.25" OD Crossover Sub',
  Price: 0,
};

export const order_line_initial_state: OrderLineProps = {
  key: 0,
  Order: 0,
  OID: 0,
  Item: 0,
  ItemQty: 0,
  QtyRemaining: 0,
  RCPins: false,
  RCBoxes: false,
  RFPins: false,
  RFBoxes: false,
  Straignten: false,
  Hardband: false,
  RCPinsQty: 0,
  RCBoxesQty: 0,
  RFPinsQty: 0,
  RFBoxesQty: 0,
  StraigntenQty: 0,
  HardbandQty: 0,
  Phosphate: false,
  PinProtector: false,
  PinProtectorQty: 0,
  BoxProtector: false,
  BoxProtectorQty: 0,
  PinHardband: false,
  PinGrind: false,
  PinGouge: false,
  BoxHardband: false,
  BoxGrind: false,
  BoxGouge: false,
  PinHardbandQty: 0,
  PinGrindQty: 0,
  PinGougeQty: 0,
  BoxHardbandQty: 0,
  BoxGrindQty: 0,
  BoxGougeQty: 0,
  PhosphateQty: 0,
  OptimisticLockField: 0,
  GCRecord: 0,
  itemDetail: item_initial_state,
};

export const OrderInventoryData: OrderInventoryProps[] = [
  {
    key: 0,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    pup: 120,
    grandTotal: 120,
  },
  {
    key: 1,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    rdc_if_dbr: 120,
    grandTotal: 120,
  },
  {
    key: 2,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    rdc_xh_dbr: 120,
    grandTotal: 120,
  },
  {
    key: 3,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 4,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    rdc_7_if: 120,
    grandTotal: 120,
  },
  {
    key: 5,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 6,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    elevators_camps_slips: 120,
    grandTotal: 120,
  },
  {
    key: 7,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 8,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 9,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 10,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 11,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 12,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 13,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 14,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 15,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 16,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 17,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 18,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 19,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 20,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 21,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    rdc_if: 75,
    grandTotal: 75,
  },
  {
    key: 22,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
  {
    key: 23,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    rdc_reg: 80,
    grandTotal: 80,
  },
  {
    key: 24,
    status: "Received",
    drillingCo: "Nabors",
    location: "N-9B",
    received: "09/07/23",
    doped: "",
    rigNo: "X20",
    nc_56_box_protector: 120,
    grandTotal: 120,
  },
];

export const userData: UserDataProps = {
  key: 0,
  Oid: 0,
  UserName: "Admin",
  IsActive: true,
  ChangePasswordOnFirstLogon: false,
};

export const user_initial_state: UserDataProps = {
  key: 0,
  Oid: 0,
  UserName: "",
  IsActive: true,
  ChangePasswordOnFirstLogon: false,
  Password: "",
  Role: "",
};

export const roleData: RoleProps = {
  key: 0,
  Oid: 0,
  Name: "Administrators",
  IsAdministrative: true,
  CanEditModel: false,
};

export const role_initial_state: RoleProps = {
  key: 0,
  Oid: 0,
  Name: "",
  IsAdministrative: false,
  CanEditModel: false,
};

export const reportListData: reportProps[] = [
  {
    key: 0,
    name: "Order Details",
    dataType: "Order",
  },
  {
    key: 1,
    name: "QTY",
    dataType: "Order",
  },
  {
    key: 2,
    name: "QTY 2",
    dataType: "Order",
  },
  {
    key: 3,
    name: "QTY 4",
    dataType: "Order",
  },
  {
    key: 4,
    name: "QTY 5",
    dataType: "Order",
  },
  {
    key: 5,
    name: "QTY3",
    dataType: "Order",
  },
];

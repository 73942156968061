import React, { useContext, useEffect } from "react";
import { Button, Checkbox, Col, Drawer, Form, Row, Select, Space } from "antd";
import { OrderLineProps } from "../../../interfaces/interfaces";
import DataContext from "../../../context/DataContext";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { InputNumber } from "antd";

interface Props {
  open: boolean;
  values: OrderLineProps;
  isEdition?: boolean;
  orderId: number;
  orderLinesLength: number;
  setValues: React.Dispatch<React.SetStateAction<OrderLineProps>>;
  onAddOrderLine?: (values: OrderLineProps) => void;
  onEditOrderLine?: (values: OrderLineProps) => void;
  onClose: () => void;
}

export const OrderLineForm = ({
  open,
  isEdition,
  orderId,
  orderLinesLength,
  onAddOrderLine,
  onEditOrderLine,
  onClose,
  values,
  setValues,
}: Props) => {
  const [form] = Form.useForm();
  const { itemList, formattedItemList } = useContext(DataContext);

  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
    event.preventDefault();
    setValues((prev) => {
      let qty = {};
      if (!event.target.checked) {
        qty = { [`${event.target.name}Qty`]: 0 };
      }
      return {
        ...prev,
        [`${event.target.name}`]: event.target.checked,
        ...qty,
      };
    });
  };

  const onChange = (value: string) => {
    const itemSelected = itemList.filter(
      (item) => item.OID === parseInt(value)
    );
    if (itemSelected.length > 0) {
      setValues((prev) => {
        return {
          ...prev,
          itemDetail: itemSelected[0],
        };
      });
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());

  return (
    <>
      <Drawer
        title={isEdition ? "Edit Order Line" : "Create Order Line"}
        width={500}
        onClose={onClose}
        maskClosable={false}
        open={open}
        footer={
          <Space className="padding-all">
            <Button onClick={onClose} danger>
              Remove
            </Button>

            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    isEdition
                      ? onEditOrderLine && onEditOrderLine(values)
                      : onAddOrderLine &&
                        onAddOrderLine({
                          ...values,
                          Order: orderId,
                          OID: orderLinesLength + 1,
                        });
                  })
                  .then(() => {
                    onClose();
                  })
                  .catch((errorInfo) => {
                    console.log("errorInfo ...", errorInfo);
                  });
              }}
            >
              Save Order Line
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" initialValues={values} form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Item"
                rules={[{ required: true, message: "Item Required" }]}
              >
                <Select
                  showSearch
                  placeholder="Select an item"
                  value={values.itemDetail.OID.toString()}
                  onChange={onChange}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={formattedItemList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Item Qty"
                name="ItemQty"
                rules={[{ required: true, message: "Item Quantity Required" }]}
              >
                <InputNumber
                  name="ItemQty"
                  placeholder="Item Qty"
                  min={0}
                  style={{ width: "100%" }}
                  value={values.ItemQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          ItemQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Qty Remaining">
                <InputNumber
                  name="QtyRemaining"
                  min={0}
                  style={{ width: "100%" }}
                  value={values.QtyRemaining}
                  onChange={(newValue) => {
                    if (newValue || newValue === 0)
                      setValues((prev) => {
                        return {
                          ...prev,
                          QtyRemaining: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="RCPins"
              >
                <Checkbox
                  name="RCPins"
                  checked={values.RCPins}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RCPins Qty">
                <InputNumber
                  name="RCPinsQty"
                  placeholder="RCPins Qty"
                  min={0}
                  style={{ width: "100%" }}
                  value={values.RCPinsQty}
                  disabled={!values.RCPins}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          RCPinsQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="RCBoxes"
              >
                <Checkbox
                  name="RCBoxes"
                  checked={values.RCBoxes}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RCBoxes Qty">
                <InputNumber
                  name="RCBoxesQty"
                  placeholder="RCBoxes Qty"
                  min={0}
                  style={{ width: "100%" }}
                  value={values.RCBoxesQty}
                  disabled={!values.RCBoxes}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          RCBoxesQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="RFPins"
              >
                <Checkbox
                  name="RFPins"
                  checked={values.RFPins}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RFPins Qty">
                <InputNumber
                  name="RFPinsQty"
                  placeholder="RFPins Qty"
                  min={0}
                  disabled={!values.RFPins}
                  style={{ width: "100%" }}
                  value={values.RFPinsQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          RFPinsQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="RFBoxes"
              >
                <Checkbox
                  name="RFBoxes"
                  checked={values.RFBoxes}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RFBoxes Qty">
                <InputNumber
                  name="RFBoxesQty"
                  placeholder="RFBoxes Qty"
                  min={0}
                  disabled={!values.RFBoxes}
                  style={{ width: "100%" }}
                  value={values.RFBoxesQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          RFBoxesQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Straignten"
              >
                <Checkbox
                  name="Straignten"
                  checked={values.Straignten}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Straignten Qty">
                <InputNumber
                  name="StraigntenQty"
                  placeholder="Straignten Qty"
                  min={0}
                  disabled={!values.Straignten}
                  style={{ width: "100%" }}
                  value={values.StraigntenQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          StraigntenQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Pin Hardband"
              >
                <Checkbox
                  name="PinHardband"
                  checked={values.PinHardband}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Pin Hardband Qty">
                <InputNumber
                  name="PinHardbandQty"
                  placeholder="Pin Hardband Qty"
                  min={0}
                  disabled={!values.PinHardband}
                  style={{ width: "100%" }}
                  value={values.PinHardbandQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          PinHardbandQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Pin Grind"
              >
                <Checkbox
                  name="PinGrind"
                  checked={values.PinGrind}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Pin Grind Qty">
                <InputNumber
                  name="PinGrindQty"
                  placeholder="Pin Grind Qty"
                  min={0}
                  disabled={!values.PinGrind}
                  style={{ width: "100%" }}
                  value={values.PinGrindQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          PinGrindQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Pin Gouge"
              >
                <Checkbox
                  name="PinGouge"
                  checked={values.PinGouge}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Pin Gouge Qty">
                <InputNumber
                  name="PinGougeQty"
                  placeholder="Pin Gouge Qty"
                  min={0}
                  disabled={!values.PinGouge}
                  style={{ width: "100%" }}
                  value={values.PinGougeQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          PinGougeQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Box Hardband"
              >
                <Checkbox
                  name="BoxHardband"
                  checked={values.BoxHardband}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Box Hardband Qty">
                <InputNumber
                  name="BoxHardbandQty"
                  placeholder="Box Hardband Qty"
                  min={0}
                  disabled={!values.BoxHardband}
                  style={{ width: "100%" }}
                  value={values.BoxHardbandQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          BoxHardbandQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Box Grind"
              >
                <Checkbox
                  name="BoxGrind"
                  checked={values.BoxGrind}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Box Grind Qty">
                <InputNumber
                  name="BoxGrindQty"
                  placeholder="Box Grind Qty"
                  min={0}
                  disabled={!values.BoxGrind}
                  style={{ width: "100%" }}
                  value={values.BoxGrindQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          BoxGrindQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Box Gouge"
              >
                <Checkbox
                  name="BoxGouge"
                  checked={values.BoxGouge}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Box Gouge Qty">
                <InputNumber
                  name="BoxGougeQty"
                  placeholder="Box Gouge Qty"
                  min={0}
                  disabled={!values.BoxGouge}
                  style={{ width: "100%" }}
                  value={values.BoxGougeQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          BoxGougeQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Phosphate"
              >
                <Checkbox
                  name="Phosphate"
                  checked={values.Phosphate}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phosphate Qty">
                <InputNumber
                  name="PhosphateQty"
                  placeholder="Phosphate Qty"
                  min={0}
                  disabled={!values.Phosphate}
                  style={{ width: "100%" }}
                  value={values.PhosphateQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          PhosphateQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Pin Protector"
              >
                <Checkbox
                  name="PinProtector"
                  checked={values.PinProtector}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="PinProtectorQty">
                <InputNumber
                  name="PinProtectorQty"
                  placeholder="PinProtector Qty"
                  min={0}
                  disabled={!values.PinProtector}
                  style={{ width: "100%" }}
                  value={values.PinProtectorQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          PinProtectorQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                className="align-center"
                valuePropName="checked"
                label="Box Protector"
              >
                <Checkbox
                  name="BoxProtector"
                  checked={values.BoxProtector}
                  onChange={handleChangeCheckbox}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Box Protector Qty">
                <InputNumber
                  name="BoxProtectorQty"
                  placeholder="BoxProtector Qty"
                  min={0}
                  disabled={!values.BoxProtector}
                  style={{ width: "100%" }}
                  value={values.BoxProtectorQty}
                  onChange={(newValue) => {
                    if (newValue)
                      setValues((prev) => {
                        return {
                          ...prev,
                          BoxProtectorQty: newValue,
                        };
                      });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

import React from "react";
import {
  UserOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  ItemPage,
  Login,
  OrderInventory,
  OrderPage,
  RolePage,
  UserPage,
} from "../../pages";

export const routes = {
  login: "/",
  order: "/order",
  item: "/item",
  inventory: "/inventory",
  account: "/account",
  rol: "/rol",
  help: "/order",
  user: "/user",
  report: "/report",
};

export const AppPages = [
  { path: routes.login, protected: false, component: <Login /> },
  { path: routes.order, protected: true, component: <OrderPage /> },
  { path: routes.item, protected: true, component: <ItemPage /> },
  { path: routes.inventory, protected: true, component: <OrderInventory /> },
  { path: routes.rol, protected: true, component: <RolePage /> },
  { path: routes.user, protected: true, component: <UserPage /> },
  // { path: routes.report, protected: true, component: <ReportPage /> },
];

export const MenuItems: MenuProps["items"] = [
  {
    label: "Orders",
    key: routes.order,
    icon: <UnorderedListOutlined className="icon" />,
  },
  {
    label: "Item",
    key: routes.item,
    icon: <ApartmentOutlined className="icon" />,
  },
  {
    label: "Order Inventory",
    key: routes.inventory,
    icon: <FileExcelOutlined className="icon" />,
  },
  {
    label: "Accounts",
    key: routes.account,
    icon: <UserOutlined className="icon" />,
    children: [
      {
        label: "Roles",
        key: routes.rol,
      },
      {
        label: "Users",
        key: routes.user,
      },
    ],
  },
  { type: "divider" },
];

import React from "react";
import { Page, StyleSheet, Text } from "@react-pdf/renderer";
import Footer from "../components/Footer";
import Table from "../components/Table";
import Line from "../components/Line";
import { Header } from "../components/Header";

const styles = StyleSheet.create({
  page: {
    padding: 50,
    paddingTop: 40,
  },
  title: {
    fontSize: 15,
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    fontWeight: 600,
    borderBottomColor: "rgb(251,171,53)",
  },
});

export const ItemPdfPage = () => {
  return (
    <Page size="A4" orientation="portrait" style={styles.page}>
      <Header title="TITLE" subtitle="SUBTITLE" />
      <Text style={styles.title}>Items</Text>
      <React.Fragment>
        <Table>
          <Table.Header>
            <Table.TableHeaderCellMain>Item</Table.TableHeaderCellMain>
            <Table.HeaderCell>Received</Table.HeaderCell>
            <Table.HeaderCell>Shipped</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Header>
        </Table>
        <Line color="yellow" />
      </React.Fragment>
      <Footer />
    </Page>
  );
};

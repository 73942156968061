import { Col } from "antd";
import React, { ReactNode } from "react";

interface Props {
  xs?: number;
  sm?: number;
  md?: number;
  xl?: number;
  lg?: number;
  children: ReactNode;
}

export const ColumnComponent = ({ xs, sm, md, xl, lg, children }: Props) => {
  return (
    <Col
      xs={xs || 24}
      sm={sm || 24}
      md={md || 24}
      lg={lg || 24}
      xl={xl || 24}
      className="mb-24"
    >
      {children}
    </Col>
  );
};

import { Card, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Datatable } from "../../components/functional";
import { OrderProps } from "../../interfaces/interfaces";
import { OrderTableColumns } from "../../utils/tables/columns";
import { CardTitle, ColumnComponent } from "../../components/ui";
import {
  FileAddOutlined,
  ExportOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useModal } from "../../hooks";
import { OrderDrawer } from "../../components/pages/order";
import DataContext from "../../context/DataContext";

export const OrderPage = () => {
  const {
    dataTableSize,
    orderData,
    selectedData,
    fetchOrderData,
    onEditOrder,
    onExportExcel,
    handleRemoveSelectedData,
  } = useContext(DataContext);
  const [orderIndex, setOrderIndex] = useState<number | undefined>(0);
  const [openOrderDrawer, setOpenOrderDrawer] = useState(false);
  const { isModalOpen, setIsModalOpen, ConfirmationModal } = useModal();

  useEffect(() => {
    fetchOrderData();
  }, []);

  const handleClickNext = (orderIndex: number) => {
    setOrderIndex(orderIndex + 1);
    onEditOrder(orderData[orderIndex + 1]);
  };

  const handleClickPrevious = (orderIndex: number) => {
    setOrderIndex(orderIndex - 1);
    onEditOrder(orderData[orderIndex - 1]);
  };
  const buttons = [
    {
      title: "New",
      icon: <FileAddOutlined />,
      disabled: false,
      action: () => {
        onEditOrder(undefined);
        setOpenOrderDrawer(true);
      },
    },
    {
      title: "Export",
      icon: <ExportOutlined />,
      disabled: !(selectedData.length > 0),
      action: () => onExportExcel("order"),
    },
    {
      title: "Remove",
      icon: <DeleteOutlined />,
      disabled: !(selectedData.length > 0),
      danger: true,
      action: () => setIsModalOpen(true),
    },
  ];
  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title="Orders" buttons={buttons} search={true} />
        </ColumnComponent>
        <ColumnComponent>
          <Card bordered hoverable className="card overflow_x">
            <Datatable<OrderProps>
              columns={OrderTableColumns}
              data={orderData}
              rowKey="OID"
              onRowClick={(order, index) => {
                setOrderIndex(index);
                onEditOrder(order);
                setOpenOrderDrawer(true);
              }}
            />
          </Card>
        </ColumnComponent>
      </Row>
      <OrderDrawer
        open={openOrderDrawer}
        onClose={() => {
          onEditOrder(undefined);
          setOpenOrderDrawer(false);
        }}
        onClickPrevious={
          orderIndex !== undefined && orderIndex > 0
            ? () => handleClickPrevious(orderIndex)
            : undefined
        }
        onClickNext={
          orderIndex !== undefined && orderIndex < dataTableSize - 1
            ? () => handleClickNext(orderIndex)
            : undefined
        }
      />
      {isModalOpen && (
        <ConfirmationModal
          title="Are you sure you want to delete?"
          onConfirm={() => handleRemoveSelectedData("orders")}
        />
      )}
    </>
  );
};

import React, { ReactNode, useState } from "react";

import { Layout, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Navbar from "../navigation/Navbar";
import { AppHeader } from "../navigation/AppHeader";
import { AppFooter } from "../navigation/AppFooter";
import { DisplayComponent } from "./DisplayComponent";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState<number>(250);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      <Layout>
        <Sider
          breakpoint="sm"
          trigger={null}
          collapsed={collapsed}
          collapsible
          width={siderWidth}
          theme="light"
          onBreakpoint={(broken) => {
            if (broken) {
              setCollapsed(true);
              setSiderWidth(100);
            }
          }}
        >
          <Navbar menuCollapsed={collapsed} />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <AppHeader
              collapsed
              handleClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          </Header>
          <DisplayComponent error={false} isLoading={false}>
            <Content className="dashboard">{children}</Content>
          </DisplayComponent>
          <Footer>
            <AppFooter />
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};

import React, { useEffect } from "react";
import { Col, Form, FormInstance, Input, Row } from "antd";
import { ItemProps } from "../../../interfaces/interfaces";

interface Props {
  values: ItemProps;
  setValues: React.Dispatch<React.SetStateAction<ItemProps>>;
  form: FormInstance<any>;
  onClickField: () => void;
}

export const ItemForm = ({ values, setValues, form, onClickField }: Props) => {
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    onClickField();
    setValues((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };
  return (
    <>
      <Form layout="vertical" initialValues={values} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="Name"
              label="Name"
              rules={[{ required: true, message: "Please provide Name" }]}
            >
              <Input
                name="Name"
                placeholder="Name"
                value={values.Name}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Description"
              name="Description"
              rules={[
                { required: true, message: "Please provide Description" },
              ]}
            >
              <Input
                name="Description"
                placeholder="Description"
                value={values.Description}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Price">
              <Input
                name="Price"
                placeholder="Price"
                value={values.Price}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Drawer, Form, Space, Tooltip } from "antd";
import { UserForm } from "./UserForm";
import { user_initial_state } from "../../../utils/data";
import DataContext from "../../../context/DataContext";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

interface Props {
  open: boolean;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
  onClose: () => void;
}

export const UserDrawer = ({
  open,
  onClickNext,
  onClickPrevious,
  onClose,
}: Props) => {
  const [form] = Form.useForm();
  const { handleEditUser, handleCreateUser, userToEdit } =
    useContext(DataContext);
  const [values, setValues] = useState(user_initial_state);

  useEffect(() => {
    userToEdit && setValues(userToEdit);
    return () => {
      setValues(user_initial_state);
    };
  }, [userToEdit]);

  return (
    <>
      <Drawer
        title={userToEdit ? "Edit User" : "Create new User"}
        width={720}
        onClose={onClose}
        maskClosable={false}
        open={open}
        extra={
          <Space>
            {userToEdit && (
              <>
                <Tooltip title="Previous Record">
                  <Button
                    disabled={!onClickPrevious}
                    icon={<LeftOutlined />}
                    onClick={onClickPrevious}
                  ></Button>
                </Tooltip>
                <Tooltip title="Next Record">
                  <Button
                    disabled={!onClickNext}
                    icon={<RightOutlined />}
                    onClick={onClickNext}
                  ></Button>
                </Tooltip>
              </>
            )}
          </Space>
        }
        footer={
          <Space className="padding-all">
            {userToEdit && (
              <Button onClick={onClose} danger>
                Remove
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    userToEdit
                      ? handleEditUser(values)
                      : handleCreateUser(values);
                  })
                  .then(() => {
                    setValues(user_initial_state);
                    onClose();
                  })
                  .catch((errorInfo) => {
                    console.log("errorInfo ...", errorInfo);
                  });
              }}
            >
              Save User
            </Button>
          </Space>
        }
      >
        <UserForm
          values={values}
          setValues={setValues}
          form={form}
          edit={!!userToEdit}
        />
      </Drawer>
    </>
  );
};

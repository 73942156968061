import React, { useEffect } from "react";
import { Checkbox, Col, Form, FormInstance, Input, Row } from "antd";
import { RoleProps } from "../../../interfaces/interfaces";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface Props {
  values: RoleProps;
  setValues: React.Dispatch<React.SetStateAction<RoleProps>>;
  form: FormInstance<any>;
}

export const RoleForm = ({ values, setValues, form }: Props) => {
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    setValues((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
    event.preventDefault();
    setValues((prev) => {
      return {
        ...prev,
        [`${event.target.name}`]: event.target.checked,
      };
    });
  };
  return (
    <>
      <Form layout="vertical" initialValues={values} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="Name"
              rules={[{ required: true, message: "Name" }]}
            >
              <Input
                name="Name"
                placeholder="Name"
                value={values.Name}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              className="align-center"
              valuePropName="checked"
              label="Is Administrative?"
            >
              <Checkbox
                name="IsAdministrative"
                checked={values.IsAdministrative}
                onChange={handleChangeCheckbox}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="align-center"
              valuePropName="checked"
              label="Can Edit Model?"
            >
              <Checkbox
                name="CanEditModel"
                checked={values.CanEditModel}
                onChange={handleChangeCheckbox}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

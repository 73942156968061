import { Modal } from "antd";
import React, { ReactNode, useState } from "react";

interface Props {
  title: string;
  content?: ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ConfirmationModal = ({
    title,
    content,
    onConfirm,
    onCancel,
  }: Props) => {
    return (
      <Modal
        title={title}
        centered
        open={isModalOpen}
        onOk={() => {
          onConfirm();
          setIsModalOpen(false);
        }}
        onCancel={() => {
          onCancel && onCancel();
          setIsModalOpen(false);
        }}
      >
        {content || ""}
      </Modal>
    );
  };
  return {
    isModalOpen,
    setIsModalOpen,
    ConfirmationModal,
  };
};

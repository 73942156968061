import React from "react";
import desktopLogo from "../../assets/images/company_logo.png";
import mobileLogo from "../../assets/images/company_logo_mobile.png";
import { Grid } from "antd";

export const Logo = ({ mobile }: { mobile: boolean }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const logo = xs || mobile ? mobileLogo : desktopLogo;
  return <img src={logo} alt="Company Logo" />;
};

import React, { useContext } from "react";
import { PublicLayout } from "./PublicLayout";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicRoute = ({ children }: Props) => {
  const { validateToken } = useContext(AuthContext);
  if (validateToken()) {
    return <Navigate to="/order" replace />;
  } else {
    return <PublicLayout>{children}</PublicLayout>;
  }
};

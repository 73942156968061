import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export const AppFooter = () => {
  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={8}>
        {"Copyright © Quattro Software"}
        <Link color="inherit" to=""></Link> {new Date().getFullYear()}
      </Col>
      <Col span={24} md={8}></Col>
      <Col span={24} md={8}></Col>
    </Row>
  );
};

import { PlusOutlined } from '@ant-design/icons'
import React from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  Upload,
} from 'antd'

const { TextArea } = Input

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e.fileList
}

export const FormComponent: React.FC = () => {
  return (
    <Form
      labelCol={{ span: 30 }}
      wrapperCol={{ span: 30 }}
      layout="horizontal"
      style={{ padding: '2rem' }}
    >
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['user', 'name']}
            label="Name"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['user', 'lastname']}
            label="Lastname"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name={['user', 'email']}
            label="Email"
            rules={[{ type: 'email', required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['user', 'location']} label="Location">
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label="Role">
            <Radio.Group size="large">
              <Radio value="apple"> Admin </Radio>
              <Radio value="pear"> User </Radio>
              <Radio value="pear"> Client </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Form.Item label="Category">
            <Select size="large">
              <Select.Option value="demo">Directive</Select.Option>
              <Select.Option value="demo">Accounting</Select.Option>
              <Select.Option value="demo">Technical</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Start Date">
                <DatePicker size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Add to default group" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Upload Picture"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Form.Item label="Additional Details">
            <TextArea rows={4} size="large" />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ textAlign: 'center' }}
        >
          <Button size="large" type="primary">
            Create
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

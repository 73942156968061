import React, { useEffect } from "react";
import { Checkbox, Col, Form, FormInstance, Input, Row } from "antd";
import { UserDataProps } from "../../../interfaces/interfaces";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface Props {
  values: UserDataProps;
  setValues: React.Dispatch<React.SetStateAction<UserDataProps>>;
  form: FormInstance<any>;
  edit?: boolean;
}

export const UserForm = ({ values, setValues, form, edit }: Props) => {
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    setValues((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
    event.preventDefault();
    setValues((prev) => {
      return {
        ...prev,
        [`${event.target.name}`]: event.target.checked,
      };
    });
  };
  return (
    <>
      <Form layout="vertical" initialValues={values} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Username"
              name="UserName"
              rules={[{ required: true, message: "Username" }]}
            >
              <Input
                name="UserName"
                placeholder="Username"
                value={values.UserName}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          {!edit && (
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please provide Password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  name="password"
                  value={values.Password}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              className="align-center"
              valuePropName="checked"
              label="Is Active?"
            >
              <Checkbox
                name="IsActive"
                checked={values.IsActive}
                onChange={handleChangeCheckbox}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

import React from "react";
import { Document } from "@react-pdf/renderer";
import { ItemPdfPage } from "../pages";

export const ItemFile = <T,>({ data }: { data: T }) => {
  return (
    <Document>
      <ItemPdfPage />
    </Document>
  );
};

import React, { useEffect } from "react";
import { useState } from "react";
import DataContext from "./DataContext";
import {
  ItemProps,
  OrderLineProps,
  OrderProps,
  ReportProps,
  RoleProps,
  UserDataProps,
} from "../interfaces/interfaces";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue } from "antd/es/table/interface";
import exportFromJSON from "export-from-json";
import { useRequest } from "../hooks";
import dayjs from "dayjs";

interface Props {
  children: JSX.Element | JSX.Element[];
}
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface FilterProps {
  status?: string[] | null;
  drillingCompanies?: string[] | null;
  rigNumbers?: string[] | null;
}

const DataProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryReportData, setInventoryReportData] = useState<
    ReportProps | undefined
  >();
  const [orderData, setOrderData] = useState<OrderProps[]>([]);
  const [itemData, setItemData] = useState<ItemProps[]>([]);
  const [userData, setUserData] = useState<UserDataProps[]>([]);
  const [roleData, setRoleData] = useState<RoleProps[]>([]);
  const [orderToEdit, setOrderToEdit] = useState<OrderProps | undefined>();
  const [itemToEdit, setItemToEdit] = useState<ItemProps | undefined>();
  const [itemList, setItemList] = useState<ItemProps[]>([]);
  const [formattedItemList, setformattedItemList] = useState<
    { label: string; value: string }[]
  >([]);
  const [drillingCompanies, setDrillingCompanies] = useState<
    { text: string; value: string }[]
  >([]);
  const [rigNumbers, setRigNumbers] = useState<
    { text: string; value: string }[]
  >([]);
  const [orderLineToEdit, setOrderLineToEdit] = useState<
    OrderLineProps | undefined
  >();
  const [roleToEdit, setRoleToEdit] = useState<RoleProps | undefined>();
  const [userToEdit, setUserToEdit] = useState<UserDataProps | undefined>();
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [dataTableSize, setTableSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      total: 100,
      onChange: (page, pageSize) => {
        setTableSize(pageSize);
        setPage(page);
        const query = localStorage.getItem("query") || undefined;
        switch (window.location.pathname) {
          case "/order":
            fetchOrderData(page, pageSize, query);
            break;
          case "/item":
            fetchItemData(page, pageSize, query);
            break;
          default:
            break;
        }
      },
    },
  });

  useEffect(() => {
    if (searchQuery) {
      localStorage.setItem("query", searchQuery);
    } else {
      localStorage.removeItem("query");
    }
  }, [searchQuery]);

  useEffect(() => {
    if (localStorage.getItem("info")) {
      getItemList();
      getReportColumns();
    }
  }, []);

  // utils

  const getItemList = () => {
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<ItemProps>({
      endpoint: "items/all",
      options,
      onSuccess: (response) => formatItemList(response.data),
    });
  };

  const getReportColumns = () => {
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<ItemProps>({
      endpoint: "orders/rig-numbers/all",
      options,
      onSuccess: (response) => {
        setRigNumbers(response.data);
      },
    });
    handleRequest<ItemProps>({
      endpoint: "orders/drilling-companies/all",
      options,
      onSuccess: (response) => {
        setDrillingCompanies(response.data);
      },
    });
  };

  const handlePagination = (pagination: any) => {
    if (pagination) {
      setTableParams((prev) => {
        return {
          ...prev,
          pagination: {
            ...prev.pagination,
            total: parseInt(pagination.total),
          },
        };
      });
    }
  };

  const handleMultipleSelect = <T,>(data: any[], selectedRows: React.Key[]) => {
    const result = data.filter((item) => selectedRows.includes(item.OID));
    setSelectedData(result);
  };

  const handleExportExcel = (type: "order" | "item") => {
    try {
      setIsLoading(true);

      let fileName = type + "_status_" + dayjs(new Date());
      let data = selectedData.map((item) => {
        type === "order" && delete item.OrderLines && delete item.OrderId;
        delete item.OptimisticLockField;
        delete item.GCRecord;
        return item;
      });
      exportFromJSON({ data, fileName, exportType: "csv" });
      setSelectedData([]);
      setIsLoading(false);
    } catch {}
  };

  const handleRemoveSelectedData = (endpoint: string) => {
    if (selectedData.length === 1) {
      let options: RequestInit = {
        method: "DELETE",
        body: JSON.stringify({ itemsToDelete: selectedData }),
      };
      handleRequest<OrderProps>({
        endpoint: `${endpoint}/`,
        options,
        onSuccess: () => fetchData(endpoint),
        onError: (error) => console.log(error),
      });
    }
  };

  const formatItemList = (itemList: ItemProps[]) => {
    setItemList(itemList);
    let data = itemList.map((item) => {
      return {
        value: item.OID.toString(),
        label: item.Name,
      };
    });
    setformattedItemList(data);
  };

  const handleSearch = (value: string) => {
    setPage(1);
    setTableSize(10);
    switch (window.location.pathname) {
      case "/order":
        fetchOrderData(1, 10, value);
        break;
      case "/item":
        fetchItemData(1, 10, value);
        break;
      default:
        break;
    }
  };

  // Fetch

  const fetchData = (endpoint: string) => {
    setSelectedData([]);
    switch (endpoint) {
      case "orders":
        return fetchOrderData(page, dataTableSize);
      case "items":
        return fetchItemData(page, dataTableSize);
      default:
        break;
    }
  };

  const fetchDataReport = ({
    status = null,
    drillingCompanies = null,
    rigNumbers = null,
  }: FilterProps) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify({
        status,
        drillingCompanies,
        rigNumbers,
        isGroupStatus: true,
      }),
    };
    handleRequest<ReportProps>({
      endpoint: "orders/report",
      options,
      onSuccess: (response) => {
        setInventoryReportData(response.data);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  const fetchOrderData = (
    page?: number,
    pageSize?: number,
    search?: string
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    const endpoint =
      page && pageSize && searchEncoded
        ? `orders/?page=${page}&perPage=${pageSize}&search=${searchEncoded}`
        : page && pageSize && !searchEncoded
          ? `orders/?page=${page}&perPage=${pageSize}`
          : !page && !pageSize && searchEncoded
            ? `orders/?search=${searchEncoded}`
            : "orders";
    handleRequest<OrderProps>({
      endpoint,
      options,
      onSuccess: (response, pagination) => {
        handlePagination(pagination);
        setOrderData(response.data);
        setIsLoading(false);
      },
      onError: () => {
        //handle error
        setIsLoading(false);
      },
    });
  };

  const fetchItemData = (page?: number, pageSize?: number, search?: string) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    const endpoint =
      page && pageSize && searchEncoded
        ? `items/?page=${page}&perPage=${pageSize}&search=${searchEncoded}`
        : page && pageSize && !searchEncoded
          ? `items/?page=${page}&perPage=${pageSize}`
          : !page && !pageSize && searchEncoded
            ? `items/?search=${searchEncoded}`
            : "items";
    handleRequest<ItemProps>({
      endpoint,
      options,
      onSuccess: (response, pagination) => {
        handlePagination(pagination);
        setItemData(response.data);
        setIsLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(false);
      },
    });
  };

  const fetchRoleData = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<RoleProps>({
      endpoint: "roles",
      options,
      onSuccess: (response) => {
        handlePagination({
          current: 0,
          totalPages: 1,
          total: response.data.length,
        });
        setRoleData(response.data);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  const fetchUserData = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<UserDataProps>({
      endpoint: "users",
      options,
      onSuccess: (response) => {
        handlePagination({
          current: 0,
          totalPages: 1,
          total: response.data.length,
        });
        setUserData(response.data);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  // Create

  const handleCreateOrder = (order: OrderProps) => {
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(order),
    };
    handleRequest<OrderProps>({
      endpoint: "orders/",
      options,
      onSuccess: () => fetchOrderData(page, dataTableSize),
      onError: (error) => console.log(error),
    });
  };

  const handleCreateItem = (item: ItemProps) => {
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(item),
    };
    handleRequest<ItemProps>({
      endpoint: "items/",
      options,
      onSuccess: () => fetchItemData(page, dataTableSize),
      onError: (error) => console.log(error),
    });
  };

  const handleCreateUser = (user: UserDataProps) => {
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(user),
    };
    handleRequest<UserDataProps>({
      endpoint: "users/",
      options,
      onSuccess: () => fetchUserData(),
      onError: (error) => console.log(error),
    });
  };

  // Edit

  const handleEditOrder = (order: OrderProps) => {
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(order),
    };
    handleRequest<OrderProps>({
      endpoint: `orders/${order.OID}`,
      options,
      onSuccess: () => fetchOrderData(page, dataTableSize),
      onError: (error) => console.log(error),
    });
  };

  const handleEditItem = (item: ItemProps) => {
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(item),
    };
    handleRequest<ItemProps>({
      endpoint: `items/${item.OID}`,
      options,
      onSuccess: () => fetchItemData(page, dataTableSize),
      onError: (error) => console.log(error),
    });
  };

  const handleEditRole = (role: RoleProps) => {
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(role),
    };
    handleRequest<RoleProps>({
      endpoint: `roles/${role.Oid}`,
      options,
      onSuccess: () => fetchRoleData(),
      onError: (error) => console.log(error),
    });
  };

  const handleEditUser = (user: UserDataProps) => {
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify(user),
    };
    handleRequest<UserDataProps>({
      endpoint: `users/${user.Oid}`,
      options,
      onSuccess: () => fetchUserData(),
      onError: (error) => console.log(error),
    });
  };

  return (
    <DataContext.Provider
      value={{
        isLoading,
        tableParams,
        dataTableSize,
        page,
        inventoryReportData,
        orderData,
        itemData,
        roleData,
        userData,
        orderToEdit,
        orderLineToEdit,
        itemToEdit,
        roleToEdit,
        userToEdit,
        itemList,
        formattedItemList,
        rigNumbers,
        drillingCompanies,
        selectedData,
        searchQuery,
        onChangeSearchQuery: (newValue: string) => setSearchQuery(newValue),
        handleSearch,
        fetchDataReport,
        fetchOrderData,
        fetchItemData,
        fetchRoleData,
        fetchUserData,
        onEditOrder: setOrderToEdit,
        onEditOrderLine: setOrderLineToEdit,
        onEditItem: setItemToEdit,
        onEditRole: setRoleToEdit,
        onEditUser: setUserToEdit,
        onMultipleSelect: handleMultipleSelect,
        onExportExcel: handleExportExcel,
        handleEditOrder,
        handleCreateOrder,
        handleCreateItem,
        handleEditItem,
        handleEditRole,
        handleCreateUser,
        handleEditUser,
        handleRemoveSelectedData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

import { Card, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Datatable } from "../../components/functional";
import { UserDataProps } from "../../interfaces/interfaces";
import { UserTableColumns } from "../../utils/tables/columns";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { FileAddOutlined, DeleteOutlined } from "@ant-design/icons";
import DataContext from "../../context/DataContext";
import { UserDrawer } from "../../components/pages/user";

export const UserPage = () => {
  const { userData, fetchUserData, onEditUser } = useContext(DataContext);
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [userIndex, setUserIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleClickNext = (userIndex: number) => {
    setUserIndex(userIndex + 1);
    onEditUser(userData[userIndex + 1]);
  };

  const handleClickPrevious = (userIndex: number) => {
    setUserIndex(userIndex - 1);
    onEditUser(userData[userIndex - 1]);
  };

  const buttons = [
    {
      title: "New",
      icon: <FileAddOutlined />,
      disabled: false,
      action: () => {
        onEditUser(undefined);
        setOpenUserDrawer(true);
      },
    },
    // {
    //   title: "Remove",
    //   icon: <DeleteOutlined />,
    //   disabled: false,
    //   danger: true,
    //   action: () => {},
    // },
  ];
  return (
    <>
      <Row>
        <ColumnComponent>
          <CardTitle title="Users" buttons={buttons} />
        </ColumnComponent>
        <ColumnComponent>
          <Card bordered hoverable className="card overflow_x">
            <Datatable<UserDataProps>
              columns={UserTableColumns}
              data={userData}
              rowKey="Oid"
              onRowClick={(user) => {
                setUserIndex(userData.indexOf(user));
                onEditUser(user);
                setOpenUserDrawer(true);
              }}
            />
          </Card>
        </ColumnComponent>
      </Row>
      <UserDrawer
        open={openUserDrawer}
        onClose={() => {
          onEditUser(undefined);
          setOpenUserDrawer(false);
        }}
        onClickNext={
          userIndex !== undefined && userIndex < userData.length - 1
            ? () => handleClickNext(userIndex)
            : undefined
        }
        onClickPrevious={
          userIndex !== undefined && userIndex > 0
            ? () => handleClickPrevious(userIndex)
            : undefined
        }
      />
    </>
  );
};
